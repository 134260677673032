<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="6">
          <FormulateInput
            id="ncm"
            v-model="filters.ncm"
            type="select-searchable"
            :label="$t('NCM')"
            :placeholder="$t('Digite para pesquisar')"
            :options="getComboNcms"
            autofocus
            @fetchSearch="ncmSearch"
          />
        </b-col>
        <b-col cols="2">
          <FormulateInput
            id="origin-state"
            v-model="filters.originState"
            type="vue-select"
            :label="$t('Estado de Origem')"
            :options="getComboStates"
          />
        </b-col>
        <b-col cols="2">
          <FormulateInput
            id="operation-type"
            v-model="filters.operationType"
            type="vue-select"
            :label="$t('Tipo de Operação')"
            :options="getComboOperationTypes"
          />
        </b-col>
        <b-col cols="2">
          <FormulateInput
            id="destination-state"
            v-model="filters.destinationState"
            type="vue-select"
            :label="$t('Estado de Destino')"
            :options="getComboStatesWithAll"
          />
        </b-col>
      </b-row>

      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Exportar')"
          @click="onDownloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="taxClassifications-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('TAX_CLASSIFICATION.NAME'), 'female')"
        :fields="fields"
        :items="taxClassifications"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="$can('Update', 'TaxClassification')"
                @click="updateTaxClassification(row)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>{{ $t('Alterar') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="taxClassifications-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <FAB
      :main-tooltip="$t('Adicionar Classificação Fiscal')"
      @click="create"
    />
  </div>
</template>

<script>
import { BTable, BPagination, BRow, BCol, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { FAB, EPageSizeSelector, EPaginationSummary } from '@/views/components'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { stringUtils } from '@/mixins'
import { mapGetters, mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import { roundDecimal } from '@/utils/number-utils'
import moment from 'moment'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    FAB,
    EFilters,
    EButton,
  },
  directives: { Ripple },
  mixins: [stringUtils],
  data() {
    return {
      loaded: false,
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/taxClassifications', [
      'taxClassifications',
      'reportData',
      'paging',
      'sorting',
      'filters',
    ]),

    ...mapGetters('common/states', ['getComboStates', 'getComboStatesWithAll']),
    ...mapGetters('pages/invoice/taxClassifications', ['getComboOperationTypes']),
    ...mapGetters('common/ncms', ['getComboNcms']),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '200px' },
        },
        { label: this.$t('Ncm'), key: 'ncm', class: 'text-center', sortable: true },
        {
          label: this.$t('Estado de Origem'),
          key: 'originState',
          class: 'text-center',
          sortable: true,
        },
        {
          label: this.$t('Tipo de Operação'),
          key: 'operationType',
          class: 'text-center',
          formatter: value => this.$t(value),
        },
        {
          label: this.$t('Estado de Destino'),
          key: 'destinationState',
          class: 'text-center',
          formatter: value => this.$t(value),
        },
        { label: this.$t('Cfop de Saída'), key: 'cfopOutput', class: 'text-center' },
        { label: this.$t('Cfop de Entrada'), key: 'cfopInput', class: 'text-center' },
        { label: this.$t('Cst Icms'), key: 'stIcms', class: 'text-center' },
        { label: this.$t('Csosn Icms'), key: 'csosnIcms', class: 'text-center' },
        {
          label: this.$t('Alíquota Icms'),
          key: 'icmsPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        {
          label: this.$t('Alíquota FCP'),
          key: 'fcpPercent',
          class: 'text-center',
          formatter: value => value * 100,
        },
        {
          label: this.$t('Redução Base Icms'),
          key: 'icmsBaseReductionPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        {
          label: this.$t('Iva'),
          key: 'iva',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        {
          label: this.$t('Alíquota Icms St'),
          key: 'icmsStPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        {
          label: this.$t('Alíquota Fcp St'),
          key: 'fcpStPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        {
          label: this.$t('Redução Base Icms St'),
          key: 'icmsStBaseReductionPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        { label: this.$t('Cst Ipi Saída'), key: 'stIpiOutput', class: 'text-center' },
        { label: this.$t('Cst Ipi Entrada'), key: 'stIpiInput', class: 'text-center' },
        {
          label: this.$t('Alíquota Ipi'),
          key: 'ipiPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        { label: this.$t('Cst Pis Saída'), key: 'stPisOutput', class: 'text-center' },
        { label: this.$t('Cst Pis Entrada'), key: 'stPisInput', class: 'text-center' },
        {
          label: this.$t('Alíquota Pis'),
          key: 'pisPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
        { label: this.$t('Cst Cofins Saída'), key: 'stCofinsOutput', class: 'text-center' },
        { label: this.$t('Cst Cofins Entrada'), key: 'stCofinsInput', class: 'text-center' },
        {
          label: this.$t('Alíquota Cofins'),
          key: 'cofinsPercent',
          class: 'text-center',
          formatter: value => roundDecimal(value * 100, 4),
        },
      ]
    },
  },

  mounted() {
    this.fetchStates()
    this.fetchTaxClassificationCombos()
    if (!this.taxClassifications.length) {
      this.getData()
    }
    setTimeout(() => {
      this.loaded = true
    }, 500)
  },

  methods: {
    ...mapActions('common/states', ['fetchStates']),
    ...mapActions('common/ncms', ['fetchNcms']),
    ...mapActions('pages/invoice/taxClassifications', [
      'fetchTaxClassificationCombos',
      'fetchTaxClassifications',
      'generateReport',
      'setPageSize',
      'setCurrentPage',
      'resetFilters',
    ]),

    async ncmSearch(loading, search) {
      try {
        loading(true)
        await this.fetchNcms({ itemSearch: search })
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchTaxClassifications()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.refreshData()
    },
    refreshData() {
      this.getData()
    },

    create() {
      this.$router.push({
        name: 'tax-classification-add',
      })
    },

    updateTaxClassification(taxClassification) {
      this.$router.push({
        name: 'tax-classification-maintain',
        params: {
          ncm: taxClassification.item.ncm,
          originState: taxClassification.item.originState,
          operationType: taxClassification.item.operationType,
          destinationState: taxClassification.item.destinationState,
        },
      })
    },

    async onDownloadReport() {
      try {
        this.fetching = true
        await this.generateReport()
        // Get the download link element from the template
        const { downloadLink } = this.$refs

        if (this.reportData) {
          // Set the URL and download attributes of the link
          downloadLink.href = this.reportData
          downloadLink.download = `Classificacao_fiscal_${moment().format()}.xlsx`

          // Click the link to start the download
          downloadLink.click()
        } else {
          this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>
