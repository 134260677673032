var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1 btn-sm-block",
                    attrs: {
                      id: "download",
                      variant: "primary",
                      icon: "download",
                      busy: _vm.fetching,
                      text: _vm.$t("Exportar"),
                    },
                    on: { click: _vm.onDownloadReport },
                  }),
                  _c("a", {
                    ref: "downloadLink",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "ncm",
                      type: "select-searchable",
                      label: _vm.$t("NCM"),
                      placeholder: _vm.$t("Digite para pesquisar"),
                      options: _vm.getComboNcms,
                      autofocus: "",
                    },
                    on: { fetchSearch: _vm.ncmSearch },
                    model: {
                      value: _vm.filters.ncm,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "ncm", $$v)
                      },
                      expression: "filters.ncm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "origin-state",
                      type: "vue-select",
                      label: _vm.$t("Estado de Origem"),
                      options: _vm.getComboStates,
                    },
                    model: {
                      value: _vm.filters.originState,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "originState", $$v)
                      },
                      expression: "filters.originState",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "operation-type",
                      type: "vue-select",
                      label: _vm.$t("Tipo de Operação"),
                      options: _vm.getComboOperationTypes,
                    },
                    model: {
                      value: _vm.filters.operationType,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "operationType", $$v)
                      },
                      expression: "filters.operationType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "destination-state",
                      type: "vue-select",
                      label: _vm.$t("Estado de Destino"),
                      options: _vm.getComboStatesWithAll,
                    },
                    model: {
                      value: _vm.filters.destinationState,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "destinationState", $$v)
                      },
                      expression: "filters.destinationState",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "taxClassifications-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("TAX_CLASSIFICATION.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.taxClassifications,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              variant: "link",
                              "toggle-class": "text-decoration-none",
                              "no-caret": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("feather-icon", {
                                        staticClass:
                                          "text-body align-middle mr-25",
                                        attrs: {
                                          icon: "MoreVerticalIcon",
                                          size: "16",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm.$can("Update", "TaxClassification")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTaxClassification(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "Edit2Icon" },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Alterar"))),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "taxClassifications-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FAB", {
        attrs: { "main-tooltip": _vm.$t("Adicionar Classificação Fiscal") },
        on: { click: _vm.create },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }